// @font-face {
//   font-family: "Sarabun";
//   src: local("Sarabun"),
//     url(../assets/fonts/Sarabun-Light.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-SemiBold";
//   src: local("Sarabun-SemiBold"),
//     url(../assets/fonts/Sarabun-SemiBold.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-Bold";
//   src: local("Sarabun-Bold"),
//     url(../assets/fonts/Sarabun-Bold.ttf) format("truetype");
// }

// @font-face {
//   font-family: "Sarabun-Regular";
//   src: local("Sarabun-Regular"),
//     url(../assets/fonts/Sarabun-Regular.ttf) format("truetype");
// }

// button,
// input,
// select,
// textarea,
// a,
// p,
// label,
// span,
// td,
// tr,
// div {
//   font-family: Sarabun;
// }

// h1,
// h2,
// h3 {
//   font-family: Sarabun-SemiBold;
// }

// h4,
// h5 {
//   font-family: Sarabun-Bold;
// }

// .form-group label {
//   font-family: Sarabun-SemiBold;
// }

@font-face {
    font-family: "BaiJamjuree";
    src: local("BaiJamjuree"),
      url(../assets/fonts/BaiJamjuree-Light.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "BaiJamjuree-SemiBold";
    src: local("BaiJamjuree-SemiBold"),
      url(../assets/fonts/BaiJamjuree-SemiBold.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "BaiJamjuree-Bold";
    src: local("BaiJamjuree-Bold"),
      url(../assets/fonts/BaiJamjuree-Bold.ttf) format("truetype");
  }
  
  @font-face {
    font-family: "BaiJamjuree-Regular";
    src: local("BaiJamjuree-Regular"),
      url(../assets/fonts/BaiJamjuree-Regular.ttf) format("truetype");
  }
  
  button,
  input,
  select,
  textarea,
  a,
  p,
  label,
  span,
  td,
  tr,
  div {
    font-family: BaiJamjuree;
  }
  
  h1,
  h2,
  h3 {
    font-family: BaiJamjuree-SemiBold;
  }
  
  h4,
  h5 {
    font-family: BaiJamjuree-Bold;
  }
  .form-group label {
    font-family: BaiJamjuree-SemiBold;
  }
  
  .page-item.active .page-link {
    background-color: #39f;
    border-color: #39f;
  }
  
  thead {
    background-color: lightblue;
  }
  
  .page-link {
    color: #39f;
  }
  
  .c-sidebar {
    background-color: #333333;
    //background-color: white;
  }
  
  // .c-sidebar-nav-title , .c-sidebar-nav-dropdown-toggle, .c-sidebar-nav-link {
  //    color: black !important;
  
  //  }
  
  .c-sidebar-brand-full {
    // background-image: url('./../assets/icons/logo.png');
    width: 80px;
    // background-color: #cccccc;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .c-login-brand {
    width: 200px;
  }
  
  .c-sidebar-brand {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  thead {
    background-color: #ffff;
    color: black;
  }
  
  //fix table after use border radius color
  table {
    
    // border-bottom: 1.5px solid #D8DBE0;
    // border-left: 1.5px solid #D8DBE0;
    // border-right: 1.5px solid #D8DBE0;
    border-top: 3px solid transparent;
    // border: 1.5px solid #eff1f5;
    /* border: 2px solid transparent; */
    // background-image: rgb(131,1,1);
    background: linear-gradient(90deg, rgba(131,1,1,1) 0%, rgba(241,72,31,1) 50%, rgba(253,175,23,1) 100%);
  }
  // table > tbody > tr:nth-child(even) , table > tbody > tr > td{
  //   background: white;
  // }
  table > tbody > tr:nth-child(even){
      background: white;
    }

  table > tbody > tr:nth-child(odd) {
    background: #F2F2F3;
  }
  .table-striped tbody tr:nth-of-type(odd){
    background: #F2F2F3;
  }
  .table-hover tbody tr:hover{
    background: #ECECEE;
  }
  // end fix table after use border radius color
  
  .breadcrumb-item {
    a {
      color: #0c4da2 !important;
    }
  }
  
  .draftBackGround {
    background-color: white !important;
  }
  
  .c-sidebar-nav-item-sub {
    padding-left: 8px;
    font-size: 13px;
  }
  
  .local-font {
    font-family: "Kanit", sans-serif !important;
  }
  
  .svd_container {
    margin-top: 10px;
  }
  
  .svd_container .svd_commercial_container {
    display: none !important;
  }
  
  // .svd-popup-editor-tab-general {
  //   display: none !important;
  // }
  
  question-actions > div > span:nth-child(2),
  question-actions > div > span:nth-child(3),
  question-actions > div > span:nth-child(6),
  question-actions > div > span:nth-child(8),
  question-actions > div > span:nth-child(9)
  // question-actions > div > span:nth-child(6),
  // question-actions > div > span:nth-child(7)
  {
    display: none !important;
  }
  
  svd-accordion > div > div:nth-child(3),
  svd-accordion > div > div:nth-child(4),
  svd-accordion > div > div:nth-child(5),
  svd-accordion > div > div:nth-child(6),
  svd-accordion > div > div:nth-child(7),
  svd-accordion > div > div:nth-child(8),
  svd-accordion > div > div:nth-child(9),
  svd-accordion > div > div:nth-child(10),
  svd-accordion > div > div:nth-child(11),
  svd-accordion > div > div:nth-child(12),
  svd-accordion > div > div:nth-child(13),
  svd-accordion > div > div:nth-child(14) {
    display: none !important;
  }
  
  .svda-title-editor-placeholder {
    font-size: 20px;
    opacity: 0.8;
  }
  
  .modal-container-custom {
    display: flex !important;
  }
  
  #svd-survey-settings {
    display: none;
  }
  
  .svd-popup-editor-tab-general {
    div.form-group {
      div[data-property="visible"],
      div[data-property="readOnly"],
      div[data-property="showTitle"],
      div[data-property="locale"],
      div[data-property="mode"],
      div[data-property="cookieName"],
      div[data-property="isRequired"],
      div[data-property="hasComment"],
      // div[data-property="inputType"],
      div[data-property="autoComplete"] {
        display: none;
      }
      .svd_custom_select select {
        option[value="color"] {
          display: none !important;
        }
        option[value="datetime"] {
          display: none !important;
        }
        option[value="email"] {
          display: none !important;
        }
        option[value="month"] {
          display: none !important;
        }
        option[value="number"] {
          display: none !important;
        }
        option[value="password"] {
          display: none !important;
        }
        option[value="range"] {
          display: none !important;
        }
        option[value="tel"] {
          display: none !important;
        }
        option[value="url"] {
          display: none !important;
        }
        option[value="week"] {
          display: none !important;
        }
      }
    }
  }
  .svd-accordion-tab-header[data-title="Validation"] {
    display: none !important;
  }
  .svd-accordion-tab-header[data-title="Show on Completed"] {
    display: none !important;
  }
  
  .svd-accordion-tab-header[data-title="Timer/Quiz"] {
    display: none !important;
  }
  
  .modal-dialog-custom {
    width: 700px;
    border: 2px solid #000;
    padding: 16px 32px 24px;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
    margin: auto;
  }
  
  .text-bold {
    font-family: "BaiJamjuree-Bold";
  }
  
  .c-sidebar.c-sidebar-light .c-sidebar-brand {
    color: #275395;
    background: #fff;
    font-weight: bold;
  }
  
  .c-sidebar.c-sidebar-light .c-sidebar-nav-link,
  .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle {
    // color: #0c4da2;
    // background: transparent;
    color: rgb(0, 0, 0) !important;
	  background: #DBDDE5 !important;
  }
  
  .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-link,
  .c-sidebar.c-sidebar-light
    .c-sidebar-nav-dropdown.c-show
    .c-sidebar-nav-dropdown-toggle {
    // color: #0c4da2;
    // background: transparent;
    color: rgb(0, 0, 0) !important;
	background: #DBDDE5 !important;
  }
  
  .c-sidebar.c-sidebar-light .c-sidebar-nav-link:hover,
  .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown-toggle:hover {
    // color: #fff;
    // background: #0c4da2;
    color: rgb(0, 0, 0) !important;
	  background: #DBDDE5 !important;
  }
  
  // .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-toggle:hover {
  // 	color: #fff;
  // 	background: #0c4da2;
  // }
  // .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-sidebar-nav-item-sub:hover {
  // 	color: #fff;
  // 	background: #0c4da2;
  // }
  // .c-sidebar.c-sidebar-light .c-sidebar-nav-dropdown.c-show .c-sidebar-nav-dropdown-items .c-sidebar-nav-item.c-sidebar-nav-item-sub:hover .c-sidebar-nav-link {
  // 	color: #fff;
  // }
  
  // เพิ่มเข้ามาทำ theme figma
  .c-sidebar .c-sidebar-nav-link .c-sidebar-nav-icon, .c-sidebar .c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon{
    color: white !important;
  }
  .c-sidebar .c-sidebar-nav-link.c-active .c-sidebar-nav-icon{
    color: black !important;
  }
  .c-sidebar .c-sidebar-nav-link, .c-sidebar .c-sidebar-nav-dropdown-toggle{
    color: white !important;
    background: transparent;
  }
  .c-app:not(.c-legacy-theme) .c-header.c-header-fixed{
    box-shadow: none;
    border: 0;
  }
  .c-app:not(.c-legacy-theme) .card {
    border: 0;
    box-shadow: 2px 2px 6px 2px #dfdfe9;
  }
  .c-header .c-header-nav .c-header-nav-link{
    font-size: 15px;
    font-weight: bold;
    color: #F15A23 !important;
  }

  .table th, .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid;
    border-top-color: #d8dbe0;
    // border-right: 1px solid;
    // border-right-color: #d8dbe0;
  }

  
  a.nav-link {
    color: #0c4da2 !important;
  }
  .btn-info {
    // color: #fff;
    background-color: #0c4da2 !important;
    border-color: #0c4da2 !important;
  }
  
  .btn-outline-info {
    color: #0c4da2 !important;
    border-color: #0c4da2 !important;
  }
  .btn-outline-info:hover {
    color: #fff !important;
    border-color: #0c4da2 !important;
    background-color: #0c4da2 !important;
  }
  
  .c-app {
    --primary: #0c4da2 !important;
    --info: #0c4da2 !important;
  }
  
  .ant-tabs-tab-active,
  .text-info {
    color: #0c4da2 !important;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #0c4da2 !important;
  }
  
  .flex-1 {
    flex: 1;
  }
  
  .text-center {
    text-align: center;
  }
  
  .ant-checkbox-disabled + span {
    color: rgba(0, 0, 0, 0.7) !important;
  }
  
  .d-flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .sidebar-icon {
    margin-right: 20px;
    height: 1.09375rem;
  }
  .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active,
  .c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle {
    // background-color: #0c4da2 !important;
    // color: #fff !important;
    color: rgb(0, 0, 0) !important;
	  background: #DBDDE5 !important;
  }
  
  span.tag-wrapper.ReactTags__tag {
    padding: 2px 10px !important;
    border-radius: 5px !important;
    background: #efefef !important;
    margin-right: 10px !important;
    font-size: 14px !important;
    .ReactTags__remove {
      border: none !important;
      line-height: 0.8px;
      font-size: 16px !important;
      padding: 0 !important;
      color: #979797;
    }
  }
  
  .sv_complete_btn {
    display: none !important;
  }
  
  #save-survey > div {
    color: #fff !important;
    background-color: #28a745 !important;
  }

  .c-sidebar.c-sidebar-light .c-sidebar-nav-link.c-active .c-sidebar-nav-icon, .c-sidebar.c-sidebar-light .c-active.c-sidebar-nav-dropdown-toggle .c-sidebar-nav-icon {
    // color: #fff !important;
    color: rgb(0, 0, 0) !important;
}
  
.card {
  // background-color: #E4E7EC;
  background-color: #FFFFFF;
}
.bg-blue{
  background-color: #26247B;
  border-radius: 0px 4px 4px 0px;
}
.c-sidebar .c-sidebar-brand{
  // background-color: #F15A22;
  background-color: transparent;
  font-size: 20px;
}
.btn-warning-custom{
  background: #E35929;
  box-shadow: -1px -2px 4px rgba(245, 175, 151, 0.45), 2px 2px 4px rgba(173, 115, 95, 0.6);
  color: #fff;
}
.btn-outline-warning {
  color: #E35929!important;
  border-color: #E35929 !important;
}
.btn-outline-warning:hover {
  color: #fff !important;
  background: #E35929 !important;
}
.c-sidebar.c-sidebar-custom {
	color: #fff;
	// background: #26247B;
  background: rgb(2,5,65);
background: linear-gradient(180deg, rgba(2,5,65,1) 0%, rgba(9,9,121,1) 35%, rgba(1,123,196,1) 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	border-right: 1px solid rgba(159, 167, 179, 0.5);
}

.c-sidebar .c-sidebar-nav-link.c-active, .c-sidebar .c-active.c-sidebar-nav-dropdown-toggle {
	// color: #fff;#011758
	// background: #5388D8;
  color: rgb(0, 0, 0) !important;
	background: #DBDDE5;
}

.ql-editor{
  min-height: 300px;
  background-color: #fff;
}

.table-striped tbody tr:nth-of-type(2n) {
	background-color: #fff;
}

.page-item.active .page-link {
	background-color: #F15A22;
	border-color: #F15A22;
}
.page-link {
	color: #F15A22;
}

.btn{
  border-radius: 4px !important;
  box-shadow: 4px 4px 10px rgba(192, 194, 196, 0.6), -4px -4px 10px rgba(255, 255, 255, 0.45);
}

.btn-primary-custom{
  color: #fff;
  background-color : #26247B;
}

.btn-primary-custom:hover{
  background-color: #fff;
  color : #26247B;
}

.btn-reverse-primary-custom{
  background-color: #5388D8;
  color : #E4E7EC;
}

.btn-reverse-primary-custom:hover{
  color: #fff;
  background-color: #26247B;
}

.btn-reverse-danger-custom{
  color: #fff;
  background-color: #d75f5b;
}

.btn-reverse-danger-custom:hover{
  color: #fff;
  background-color: #d41b15;
}
.card-section-header{
  padding: 0 15px 15px 15px;
  font-size: 1.5rem;
  border-bottom: 2px solid #E35929;
}
.c-header {
	// background: #F15A22;
	// border-bottom: 1px solid #d8dbe0;
  // box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  background: #FFFFFF;
}

.c-body {
	// background-color: #fff;
  background-color: #E9EAF5;
}

.c-switch-slider {
	background-color: #c4c9d0;
	border-color: #c4c9d0;
}
.table{
  background-color: #fff;
}

.form-control:disabled, .form-control[readonly] {
	background-color: #fff;
}
.badge {
	padding: 0.75em 1.2em;
}
table.table thead{
  // color: #fff;
	// background-color: #F15A22;
  color: #768192;
	background-color: #FFFFFF;
}
.table-header{
  text-decoration: underline #F15A22 2px;
}
.custom-underline{
  height: 2px ;
  width: 75px ;
  // background-color: #F15A22
  background: linear-gradient(25deg, rgba(131,1,1,1) 0%, rgba(241,72,31,1) 50%, rgba(253,175,23,1) 100%)
}
.custom-search-card{
  background-color: #fff;
  border-color: #E4E7EC;
}

#headerTitle {
  margin: 0px 0px 10px 0px;
  
  .title{
    padding: 0px;
  }
  .path{
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 0px;
  }
  .path-text{
    margin: 0px;
  }
}

#searchDetail{
  display: flex;
  justify-content: center;
  margin: 10px;

  .label{
    display: flex;
    justify-content: end;
    align-items: center;
  }
}

.checkbox{
  margin-left: 20px;
}

.row{
  margin-bottom: 10px;
}

// figma btn
.btn-secondary-custom{
  color: rgb(0, 0, 0);
  background-color : #6c757d;
}

.btn-secondary-custom:hover{
  background-color: rgb(0, 0, 0);
  color : #6c757d;
}

.btn-reverse-secondary-custom{
  background-color: #adb7be;
  color : #000000;
}

.btn-reverse-secondary-custom:hover{
  color: rgb(0, 0, 0);
  background-color: #6c757d;
}


.btn-success-custom{
  color: #fff;
  background-color : 28a745;
}

.btn-success-custom:hover{
  background-color: #fff;
  color : #28a745;
}

.btn-reverse-success-custom{
  background-color: #00ce68;
  color : #eaecf0;
}

.btn-reverse-success-custom:hover{
  color: #fff;
  background-color: #28a745;
}

//apex chart
.apexcharts-canvas{
  margin: auto;
} 

@media (min-width: 576px) {
  .fix-mt-row{
    margin-top: -60px;
  }
}

.c-sidebar-nav-dropdown-items{
  .c-sidebar-nav-item{
    padding-left: 10px;
  }
  .c-sidebar-nav-dropdown{
    padding-left: 10px;
  }
}

.c-sidebar-nav-link{
  white-space: break-spaces;
}

body{
  font-weight: 600;
}
.ant-image-preview-operations-wrapper .ant-image-preview-operations .ant-image-preview-operations-operation .anticon{
  color: #000000;
}

.img-wrap {
  position: relative;
}
.img-wrap .delete-image-btn {
  position: absolute;
  top: 0px;
  right: 20px;
  z-index: 100;
  border-radius: 25%;
  cursor: pointer;
}